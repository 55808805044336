/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Cuadro_negro(props) {
  const { nodes, materials } = useGLTF('/cuadro_negro.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.001']} position={[-0.02, 0.08, -0.01]} rotation={[5, 5, 5]}/>
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.002']} position={[0, 0.2, -0.01]} scale={1.73}  />
     
      <pointLight position={[-3.1, 0.3, 0]} intensity={0.1} color="#fff" />
    </group>
  )
}

useGLTF.preload('/cuadro_negro.gltf')
