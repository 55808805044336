/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF} from '@react-three/drei'

export function Spaceship(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/spaceship.gltf')
  //const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" position={[10, -0.9, 0]} scale={0.3}>
        <group name="Point001" position={[1.34, -1.06, -1.57]} rotation={[0, 0.04, 0]} scale={0.61} />
        <group name="Point002" position={[-1.42, -1.03, -1.57]} rotation={[0, 0.04, 0]} scale={0.66} />
        <group name="Point003" position={[1.28, 0.11, -8.22]} rotation={[0, 0.04, -0.04]} />
        <group name="Point004" position={[-0.87, 0.29, -8.2]} rotation={[0, 0.04, -0.04]} />
        <group name="Area" position={[-2.91, -0.49, -1.63]} rotation={[-1.58, 0.02, -1.27]} scale={[2.47, 1, 1]} />
        <group name="Area001" position={[2.94, -0.54, -1.82]} rotation={[1.59, -0.01, -1.24]} scale={[-2.46, -1, -1]} />
        <group name="Point005" position={[1.15, 0.25, -5.54]} rotation={[1.57, 0, -0.02]} scale={3.74} />
        <group name="Point006" position={[-1.07, 0.26, -5.54]} rotation={[1.57, 0, -0.02]} scale={4.08} />
        <group name="Empty" position={[-1.07, 0.26, -5.54]} />
        <group name="Empty001" position={[1.15, 0.25, -5.54]} />
        <mesh name="Baked_Animations_Intergalactic_Spaceships_Version_2" geometry={nodes.Baked_Animations_Intergalactic_Spaceships_Version_2.geometry} material={materials['Material.001']} rotation={[Math.PI / 2, 0, 0]} />
        <mesh name="Circle" geometry={nodes.Circle.geometry} material={materials['Material.002']} position={[-1.03, 0.18, -5.28]} scale={[1, 1, 1.25]} />
        <mesh name="Circle001" geometry={nodes.Circle001.geometry} material={materials['Material.002']} position={[1.03, 0.18, -5.27]} scale={[1, 1, 1.25]} />
        <mesh name="Torus001" geometry={nodes.Torus001.geometry} material={materials['Material.003']} position={[-1.03, 0.18, -7.73]} rotation={[0, 0, -2.51]} scale={0.47} />
        <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials['Material.003']} position={[-1.03, 0.18, -2.76]} />
        <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials['Material.003']} position={[1.03, 0.18, -7.72]} rotation={[0, 0, -2.51]} scale={0.47} />
        <mesh name="Torus000" geometry={nodes.Torus000.geometry} material={materials['Material.003']} morphTargetDictionary={nodes.Torus000.morphTargetDictionary} morphTargetInfluences={nodes.Torus000.morphTargetInfluences} position={[1.03, 0.18, -8.7]} rotation={[0, 0, -1.88]} scale={0.11} />
        <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials['Material.003']} morphTargetDictionary={nodes.Torus002.morphTargetDictionary} morphTargetInfluences={nodes.Torus002.morphTargetInfluences} position={[-1.03, 0.18, -6.75]} rotation={[0, 0, -1.88]} scale={0.84} />
        <mesh name="Torus003" geometry={nodes.Torus003.geometry} material={materials['Material.003']} morphTargetDictionary={nodes.Torus003.morphTargetDictionary} morphTargetInfluences={nodes.Torus003.morphTargetInfluences} position={[-1.03, 0.18, -5.77]} rotation={[0, 0, -0.63]} scale={1.06} />
        <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials['Material.003']} morphTargetDictionary={nodes.Torus005.morphTargetDictionary} morphTargetInfluences={nodes.Torus005.morphTargetInfluences} position={[1.03, 0.18, -5.76]} rotation={[0, 0, -0.63]} scale={1.06} />
        <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials['Material.003']} morphTargetDictionary={nodes.Torus006.morphTargetDictionary} morphTargetInfluences={nodes.Torus006.morphTargetInfluences} position={[1.03, 0.18, -6.74]} rotation={[0, 0, -1.88]} scale={0.84} />
      </group>
    </group>
  )
}

useGLTF.preload('/spaceship.gltf')
